<template>
	<div>
		<LndHeader />

		<main class="main-page main-content">
			<LndDexnodeFuture @buy="isPopupCheckoutActive = true" />
			<LndDexnodeSafety />
			<LndDexnodeAccess />
			<LndDexnodePerformance @buy="isPopupCheckoutActive = true"/>
			<LndDexnodeСharacteristics />
			<LndDexnodeSize />

			<div>
				<section class="block-content">
					<NodeAnimation :timeout="1000"/>
				</section>
			</div>

			<LndDexnodeControl @buy="isPopupCheckoutActive = true"/>
			<LndDexnodeFAQ />
		</main>

		<LndFooter />

		<LndScripts />

		<LndPopupCheckout
			v-if="isPopupCheckoutActive"
			@close="isPopupCheckoutActive = false"
		/>
	</div>
</template>
<script>

import LndHeader from '@/components/lnd/Header.vue'
import LndScripts from '@/components/lnd/Scripts.vue';
import LndFooter from '@/components/lnd/Footer.vue';

import LndPopupCheckout from '@/components/lnd/PopupCheckout.vue'

import NodeAnimation from '@/components/lnd/dexnode/children/NodeAnimation.vue'

import LndDexnodeFuture from '@/components/lnd/dexnode/Future.vue'
import LndDexnodeSafety from '@/components/lnd/dexnode/Safety.vue'
import LndDexnodeAccess from '@/components/lnd/dexnode/Access.vue'
import LndDexnodePerformance from '@/components/lnd/dexnode/Performance.vue'
import LndDexnodeСharacteristics from '@/components/lnd/dexnode/Сharacteristics.vue'
import LndDexnodeSize from '@/components/lnd/dexnode/Size.vue'
import LndDexnodeControl from '@/components/lnd/dexnode/Control.vue'
import LndDexnodeFAQ from '@/components/lnd/dexnode/FAQ.vue'

export default {
	name: 'LandingDexNode',
	components: {
		LndHeader,
		LndScripts,
		LndFooter,

		LndPopupCheckout,

		NodeAnimation,

		LndDexnodeFuture,
		LndDexnodeSafety,
		LndDexnodeAccess,
		LndDexnodePerformance,
		LndDexnodeСharacteristics,
		LndDexnodeSize,
		LndDexnodeControl,
		LndDexnodeFAQ,
	},
	data: () => ({
		isPopupCheckoutActive: false,
	}),
	mounted() {
		console.log('LandingDexNode mounted');
		setTimeout(() => window.scrollTo(0, 0), 50)
	}
}
</script>
<style>
.main-content {
	display: grid;
	gap: 200px;
	padding-top: 44px;
	margin-bottom: 100px;
	max-width: 1400px;
	margin-left: auto;
	margin-right: auto;
}

@media (min-width: 768px) {
	.main-content {
		padding-top: 100px;
		gap: 300px;
		margin-bottom: 150px;
	}
}

@media (min-width: 1024px) {
	.main-content {
		padding-top: 171px;
		margin-bottom: 200px;
	}
}

@media (min-width: 1190px) {
	.main-content {
		padding-top: 104px;
		gap: 400px;
	}
}

.block-content {
	width: 100%;
	max-width: 960px;
	margin: 0 auto;
	padding: 0 16px;
}

@media (min-width: 768px) {
	.block-content {
		padding: 0 32px;
	}
}

.block-content__title {
	font-weight: 700;
	text-align: center;
	font-size: 36px;
	margin-bottom: 32px;
	line-height: 1.25;
}

.block-content__title_break_all {
	word-break: break-all;
}

@media (min-width: 768px) {
	.block-content__title {
		font-size: 56px;
		margin-bottom: 41px;
	}
}

@media (min-width: 1190px) {
	.block-content__title {
		margin-bottom: 46px;
		line-height: 1.2;
	}
}

.block-content__title-accent {
	color: var(--accent-color);
	display: block;
}

.block-content__title-accent_inline {
	display: inline;
}

.block-content__buttons {
	display: flex;
	flex-direction: column;
	width: 219px;
	margin: 0 auto 32px;
	gap: 16px;
}

@media (min-width: 768px) {
	.block-content__buttons {
		flex-direction: row;
		gap: 12px;
		width: auto;
		justify-content: center;
	}
}

@media (min-width: 768px) {
	.block-content__buttons .button {
		padding: 16px 24px;
	}
}

.block-content__images {
	margin-bottom: 56px;
}

.block-content .entry-content {
	font-weight: 400;
	font-size: 24px;
	line-height: 142%;
	font-family: var(--accent-font), serif;
	display: grid;
	gap: 33px;
}

@media (min-width: 768px) {
	.block-content .entry-content {
		font-size: 40px;
		line-height: 140%;
		gap: 57px;
	}
}

.block-content .entry-content .button {
	margin-right: auto;
}

.button-play {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
}

.button-play:before {
	content: "";
	display: block;
	width: 19px;
	height: 19px;
	background: transparent url(../../assets/images/play.svg) no-repeat center/contain;
}

.text-accent {
	color: var(--accent-color);
}

.block-content__images {
	margin-bottom: 56px;
}

.big-content {
	padding: 0 16px;
	overflow: hidden;
}

@media (min-width: 768px) {
	.big-content {
		padding: 0 32px;
	}
}

@media (min-width: 1190px) {
	.big-content {
		padding: 0 56px;
	}
}

@media (min-width: 1200px) {
	.big-content {
		padding: 0 28px;
	}
}

@media (min-width: 768px) {
	.big-content__text {
		font-size: 22px;
		line-height: 160%;
	}
}

.big-content__animation-block {
	margin-top: 56px;
	/* margin-right: -16px; */
	/* padding: 3px 7px; */
}

@media (min-width: 768px) {
	.big-content__animation-block {
		padding: 6px 0px;
		/* margin-right: -32px; */
		margin-top: 76px;
	}
}

@media (min-width: 1190px) {
	.big-content__animation-block {
		margin-top: 135px;
	}
}

@media (min-width: 1200px) {
	.big-content__animation-block {
		margin-top: 100px;
	}
}

.animation-grid {
	display: grid;
	grid-template-columns: repeat(14, 1fr);
	gap: 24px;
	overflow: hidden;
}

@media (min-width: 768px) {
	.animation-grid {
		gap: 33px;
	}
}

@media (min-width: 1024px) {
	.animation-grid {
		gap: 32px;
	}
}

@media (min-width: 1200px) {
	.animation-grid {
		gap: 40px;
	}
}

.animation-grid__item {
	width: 35px;
	height: 35px;
	display: block;
	background: transparent url(../../assets/images/animation-item.svg) no-repeat center/contain;
}

@media (min-width: 768px) {
	.animation-grid__item {
		width: 46px;
		height: 46px;
	}
}

@media (min-width: 1200px) {
	.animation-grid__item {
		width: 56px;
		height: 56px;
	}
}

/* .animation-grid__item_fill {
  background-image: url("../images/icons/animation-item-fill.svg");
} */

.section-characteristic__title {
	font-size: 28px;
	margin-bottom: 30px;
}

@media (min-width: 768px) {
	.section-characteristic__title {
		font-size: 48px;
		margin-bottom: 40px;
	}
}

.section-characteristic__cards {
	display: grid;
	gap: 16px;
}

@media (min-width: 768px) {
	.section-characteristic__cards {
		gap: 32px;
	}
}

@media (min-width: 1024px) {
	.block-columns {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 32px;
	}
}

@media (min-width: 1200px) {
	.block-columns {
		gap: 20px;
	}
}

.section-control__title {
  margin-bottom: 40px;
}
.section-control__btn {
  margin: 0 auto;
  display: block;
}

.faqs-block__item {
  padding: 16px 0;
  display: grid;
  gap: 18px;
  position: relative;
}
@media (min-width: 768px) {
  .faqs-block__item {
    padding: 32px 0;
  }
}
@media (min-width: 1190px) {
  .faqs-block__item:first-of-type {
    padding-top: 10px;
  }
}
.faqs-block__item:not(:last-of-type):after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background: rgb(126, 206, 202);
  background: linear-gradient(90deg, rgba(126, 206, 202, 0) 0%, rgb(126, 206, 202) 15%, rgb(126, 206, 202) 85%, rgba(126, 206, 202, 0) 100%);
  opacity: 0.25;
}
.faqs-block__item_active .faqs-block__content {
  max-height: 550px;
}
.faqs-block__item_active .faqs-block__title:after {
  transform: rotate(180deg);
}
.faqs-block__title {
  font-weight: 500;
  font-size: 18px;
  color: #daf1f0;
  font-family: var(--main-font, sans-serif);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  cursor: pointer;
}
@media (min-width: 768px) {
  .faqs-block__title {
    font-size: 28px;
  }
}
@media (min-width: 1200px) {
  .faqs-block__title {
    padding-right: 15px;
  }
}
.faqs-block__title:after {
  content: "";
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  background: transparent url(../../assets/images/faq-arrow.svg) no-repeat center/contain;
  transition: all 0.3s linear;
}
@media (min-width: 768px) {
  .faqs-block__title:after {
    width: 42px;
    height: 42px;
  }
}
.faqs-block__content {
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: rgba(162, 220, 217, 0.9);
  display: block;
  overflow: hidden;
  height: 0;

  transition: all 0.3s linear;

  height: auto;
  max-height: 0px;
}
@media (min-width: 768px) {
  .faqs-block__content {
    font-size: 22px;
  }
}
</style>