<template>
    <section class="big-content section section-characteristic">
        <div class="block-columns section-characteristic__header">
            <div class="block-columns__col-title">
                <h2 class="section__title section-characteristic__title">
                    <span class="text-accent">{{ $t('Technical') }}</span> {{ $t('characteristics') }}
                </h2>
            </div>

            <div class="block-columns__col-content section-characteristic__cards">
                <article class="section-specs__card specs-card">
                    <p class="specs-card__subtitle">{{ $t('Independent') }}</p>
                    <h3 class="specs-card__title">{{ $t('Communication Channels') }}</h3>
                    <p class="specs-card__text">{{ $t('lnd.specs.d1') }}</p>
                </article>
                <article class="section-specs__card specs-card">
                    <p class="specs-card__subtitle">{{ $t('Effective') }}</p>
                    <h3 class="specs-card__title">{{ $t('4 GB RAM') }}</h3>
                    <p class="specs-card__text">
                        {{ $t('lnd.specs.d2') }}
                    </p>
                </article>
                <article class="section-specs__card specs-card">
                    <p class="specs-card__subtitle">Обёмный</p>
                    <h3 class="specs-card__title">SSD Хранилище на 1 TB </h3>
                    <p class="specs-card__text">
                        {{ $t('lnd.specs.d3') }}
                    </p>
                </article>
                <article class="section-specs__card specs-card">
                    <p class="specs-card__subtitle">{{ $t('lnd.specs.t4') }}</p>
                    <h3 class="specs-card__title">{{ $t('lnd.specs.s4') }}</h3>
                    <p class="specs-card__text">
                        {{ $t('lnd.specs.d4') }}
                    </p>
                </article>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'LndCharacteristics',
}
</script>