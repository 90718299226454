<template>
    <section class="big-content section-faqs">
        <div class="block-columns section-faqs__columns">
            <div class="block-columns__col-title">
                <h2 class="section__title section-faqs__title">
                    {{ $t('Frequently asked') }} <span class="text-accent">{{ $t('questions') }}</span>
                </h2>
            </div>

            <div class="block-columns__col-content">
                <ul class="faqs-block">
                    <template v-for="(item, index) in items">
                        <li
                            class="faqs-block__item"
                            :key="index"
                            :class="{ 'faqs-block__item_active': activeItems[index] }"
                            @click="toggleItem(index)"
                        >
                            <h3 class="faqs-block__title">{{ item.title }}</h3>

                            <div class="faqs-block__content">
                                <template v-if="Array.isArray(item.content)">
                                    <template v-for="(content, i) in item.content">
                                        <p
                                            class="faqs-block__text"
                                            v-html="content"
                                            :key="i"
                                        />
                                    </template>
                                </template>
                                <template v-else>
                                    <p v-html="item.content"></p>
                                </template>
                            </div>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'LndFAQ',
    data: () => ({
        activeItems: {}
    }),
    computed: {
        items() {
            return [
                {
                    title: this.$t('How is DexNode different from a Wi-Fi router?'),
                    content: [
                        this.$t('DexNode is a node (node) in a decentralized network that is responsible for storing data, interacting with other nodes and processing transactions.'),
                        this.$t('A Wi-Fi router is a device designed to create a wireless network that allows you to connect various devices (laptops, smartphones, tablets, etc.) to the Internet via Wi-Fi. The router routes data between the Internet and connected devices.<br>That is, the main difference lies in their purpose: DexNode is an element of a decentralized network, and a Wi-Fi router is a device for organizing a wireless network.')
                    ]
                },
                {
                    title: this.$t('To which countries do you ship the device?'),
                    content: this.$t('We ship to almost all countries in Europe and Asia. For more detailed information, please contact the support service.')
                },
                {
                    title: this.$t('What is the shipping cost?'),
                    content: this.$t('The cost of obtaining DexNode at the company`s Representative Office is $130. For more detailed information on the work of the Representative Offices, please contact the support service.<br>The cost of receiving the device at the office in Dubai is free. Please check with the support service for details.')},
            ]
        }
    },
    methods: {
        toggleItem(index) {
            this.$set(this.activeItems, index, !this.activeItems[index]);
        }
    }
}
</script>